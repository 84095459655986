import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import EasylogIntro from '../components/easylog/EasylogIntro'
import FourFactors from "../components/easylog/FourFactors"
import Seo from "../components/App/Seo"
import FooterLanding from "../components/App/FooterLanding"

const AboutUs = () => {
  return (
    <Layout>
      <Seo title={"Easylog jetzt umstellen - DHL Versandsoftware läuft aus."}
           description={"DHL hat die Entwicklung von Easylog eingestellt, " +
           "sodass die Notwendigkeit besteht die DHL Versandsoftware umzustellen, " +
           "da wichtige Funktionen nur noch eine gewissen Zeit lauffähig sein werden."}
           image={"/images/og/Logentis-easylog-og.png"}
      />
      <Navbar showNavigation={false} showNetversysButton={true}/>
      <EasylogIntro />
      <FourFactors />
      <FooterLanding/>
    </Layout>
  );
}


export default AboutUs;
