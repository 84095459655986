import React from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-bootstrap"
import LandingPageForm from "../Common/LandingPageForm"


const EasylogIntro = () => {
  return (
    <section className="about-area bg-fafafb pt-4 pb-4 px-5" style={{ marginTop: "6%" }}>
      <div className="container-fluid">
        <Row className="align-items-center">
          <Col lg={8} md={12}>
            <div className="about2-content">
              <div className="content">
                <h1>DHL Versandsoftware Easylog wird eingestellt</h1>
                <p className={"border-left pl-3 border-primary"}>
                  Das bedeutet ganz konkret für alle Kunden, dass die Nutzung nur noch eine begrenzte
                  Zeit möglich sein wird, da keine Wartung oder gar Weiterentwicklung mehr erfolgen wird.
                  Gerade im Bereich der Zollformulare, wo gesetzliche Änderungen an der Tagesordnung sind,
                  entfällt also die Unterstützung.
                </p>

                <h2 className={"h4 text-primary"}>Inkompatibel mit neuerem Windows</h2>
                <p>
                  Falls Sie Windows upgraden, laufen Sie überdies ständig Gefahr, dass Easylog nicht
                  mehr funktionieren wird, da es keine weitere Unterstützung geben wird. Dies gilt auch
                  für weitere Komponenten wie Drucker oder andere Peripherie, die an Easylog
                  angeschlossen ist.
                </p>

                <h2 className={"h4  text-primary"}>Die Chance ein modernes Versandsystem nutzen</h2>
                <p>
                  Sehen Sie das Aus von Easylog als Chance auf ein modernes, browserbasiertes Versandsystem
                  wie <Link to={"/versandsoftware/"}>NETVERSYS</Link> zu setzen, welches Ihnen alle Möglichkeiten
                  einer onPremise Installation bietet, als auch eine Installation in der Cloud.
                </p>
              </div>
            </div>
          </Col>
          <Col lg={4} md={12}>
            <LandingPageForm/>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default EasylogIntro
