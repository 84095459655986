import React from "react"
import {Row, Col, Container} from 'react-bootstrap'
import {Link} from 'gatsby'
import serviceIcon1 from "../../assets/images/easylog/Delivery_left.svg"
import serviceIcon2 from "../../assets/images/easylog/rocket.svg"
import serviceIcon3 from "../../assets/images/easylog/Checklist.svg"
import serviceIcon4 from "../../assets/images/versandlogistik/flexibility.svg"


const FourFactors = () => {
  return (
    <section className="services-area pt-5 pb-70 bg-f1f8fb">
      <Container>
        <div className="section-title pt-5">
          <h2>4 Gründe warum NETVERSYS besser ist</h2>
        </div>

        <Row>
          <Col sm={6} className={"p-3"}>
            <div className="single-services-item-box h-100">
              <div className="icon">
                <img src={serviceIcon2} alt="Alle Betriebsysteme oder in der Cloud" style={{width: "58px"}}/>
              </div>
              <h3>
                  Alle Betriebsysteme oder in der Cloud
              </h3>
              <p>
                Durch seinen modernen, browserbasierten Ansatz auf Basis von Java Technolgie, arbeitet NETVERSYS
                auf jedem Betriebssystem oder gar vollständig in der Cloud. Sie haben auch die volle Auswahl
                von Arbeitsplatzhardware und Betriebssystem. NETVERSYS benötigt nur einen Browser um seine
                Stärken auszuspielen.
              </p>
            </div>
          </Col>

          <Col sm={6} className={"p-3"}>
            <div className="single-services-item-box h-100">
              <div className="icon">
                <img src={serviceIcon1} alt="Multicarrier anstatt Singlecarrier" style={{width: "70px"}}/>
              </div>
              <h3>
                  Multicarrier anstatt Singlecarrier
              </h3>
              <p>
                Easylog ist ein Singlecarrier System. Dies hat den Vorteil, dass auch die unbekanntesten DHL
                Produkte unterstützt werden, jedoch Kunden nicht die Chance haben auf mehrere Carrier zu setzen.
                Selten ist ein Carrier in allen Punkten der Spitzenreiter. Spätestens bei den Kosten gibt es riesige
                Unterschiede. <Link to={"/versandsoftware/"}>NETVERSYS</Link> versetzt Sie in die Lage, kurzfristig
                den Carrier zu wechseln oder für spezielle Versandprodukte auf verschiedene Carrier zu setzen.
              </p>
            </div>
          </Col>

          <Col sm={6} className={"p-3"}>
            <div className="single-services-item-box h-100">
              <div className="icon">
                <img src={serviceIcon3} alt="Automatisierung in der Versandlogistik"  style={{width: "73px"}}/>
              </div>

              <h3>
                  Integration in ein Lagerverwaltungssystem
              </h3>

              <p>
                Mit <Link to={"/lagerverwaltungssoftware/"}>NETSTORSYS</Link> bietet LOGENTIS auch ein
                kompaktes und leistungsfähiges Warehouse Management System an. Die Integration in NETVERSYS
                ist natürlich von Haus aus in bester Weise umgesetzt. Nutzen Sie doch beide Produkte als Tandem
                für ein gut funktionierendes Lager.
              </p>

            </div>
          </Col>

          <Col sm={6} className={"p-3"}>
            <div className="single-services-item-box h-100">
              <div className="icon">
                <img src={serviceIcon4} alt="Flexibilität in der Versandlogistik"  style={{width: "70px"}}/>
              </div>

              <h3>
                  Wir sind flexibler als DHL
              </h3>

              <p>
                Haben Sie schon einmal einen Featurerequest bei einem großen Konzern wie DHL gestellt? Sie werden
                festgestellt haben, dass es schwer ist, Softwareänderungen in solchen Strukturen durchzusetzen.
                LOGENTIS hat seinen Fokus klar auf den Kunden. Und falls Sie einmal eine ausgefallen Idee für ein
                Feature innerhalb von NETVERSYS haben, so setzen wir auch das mit einem Plugin für Sie um. Ein "das
                Feature passt nicht in die Philosophie" werden Sie von uns nicht hören.
              </p>

            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default FourFactors
